import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const SidebarContainer = styled.aside`
position: fixed;
z-index: 999;
width: 100%;
height: 100%;
background: #0d0d0d;
display: grid;
align-items: center;
top: 0;
left: 0;
transition: 0.3s ease-in-out;
opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};

`;
export const CloseIcon = styled(FaTimes)`
  color: #fff;
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const SidebarWrapper = styled.div`
  color: #fff;
`;
export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`;
export const SidebarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #c13b3a;
    transition: 0.2 ease-in-out;
  }
`;
export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;
export const SidebarRoute = styled(LinkR)`
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 16px 64px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;
export const SocialMediaWrap = styled.div`
display: flex;
align-items: center;
margin-left: 50px;


 @media screen and (max-width: 768px){
     flex-direction: column:
     justify-content: space-around;
     margin: auto;
 }

`
export const SocialIcons = styled.div`
display: flex;
justify-content: space-around;
align-items: center;
width: 150px;
vertical-align:middle;
`

export const SocialIconLink = styled.a`
color: #fff;
font-size: 24px;
`
export const NavH1 = styled.h1`
  font-size: 14px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  margin-left: 20px;
  align-items: center;

  &.active {
    color: #c13b3a;
  }
`;
export const NavH2 = styled.h2`
  font-size: 14px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  margin-left: 30px;
  align-items: center;

  &.active {
    color: #c13b3a;
    font-weight: 800;
  }
`;

export const NavTypeContainer = styled.div`
display: flex;
align-items: center;
margin: auto;
justify-content: space-between;
`
